import React from 'react';

import Head from 'next/head';
// import Script from 'next/script';
import PropTypes from 'prop-types';

import 'react-loading-skeleton/dist/skeleton.css';

const App = ({ Component, pageProps }) => (
  <>
    <Head>
      {/* <!-- Viewport --> */}
      <meta
        name='viewport'
        content='width=device-width, initial-scale=1'
      />

      {/* <!-- Bing Webmaster Tools --> */}
      <meta
        name='msvalidate.01'
        content='EF61FCB0EE27FCF3988CB8AF3C21CFBA'
      />

      {/* <!-- Yandex Webmaster Tools --> */}
      <meta
        name='yandex-verification'
        content='1edbcb118c56c4c8'
      />

      {/* <!-- Pinterest --> */}
      <meta
        name='p:domain_verify'
        content='2a0fedf0226944e725cb15f383962c84'
      />
    </Head>

    {/* {pageProps?.isDataPage ? (
        <Script
          id='widget-match-live'
          strategy='afterInteractive'
        >
          {`
          (function(a,b,c,d,e,f,g,h,i){a[e]||(i=a[e]=function(){(a[e].q=a[e].q||[]).push(arguments)},i.l=1*new Date,i.o=f,
            g=b.createElement(c),h=b.getElementsByTagName(c)[0],g.async=1,g.src=d,g.setAttribute("n",e),h.parentNode.insertBefore(g,h)
            )})(window,document,"script","https://widgets.sir.sportradar.com/d2338c3ef41aadd4e8bab0fa22f47629/widgetloader","SIR", {
                language: 'br',
            });
        `}
        </Script>
      ) : null} */}

    {/* <GoogleAnalytics /> */}

    <Component {...pageProps} />
  </>
);

App.propTypes = {
  Component: PropTypes.func.isRequired,
  pageProps: PropTypes.shape({
    URI: PropTypes.string,
    isDataPage: PropTypes.bool,
    contentData: PropTypes.shape({
      type: PropTypes.string,
    }),
  }).isRequired,
};

export default App;
